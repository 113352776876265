import { createSlice } from "@reduxjs/toolkit";

const initialSFTState = {
  actionsLoading: false,
  carouselIndex: 0,
  SFTData: [],
  filteredData: [],
  classifiedData: [
    { proccesseName: "其他", color: "#c280ff", data: [] },
    { proccesseName: "背膠", color: "#eff", data: [] },
    { proccesseName: "裁切/沖型", color: "#f0e199", data: [] },
    { proccesseName: "QC", color: "#efb5b9", data: [] },
    { proccesseName: "修邊", color: "#9cffd0", data: [] },
    { proccesseName: "入庫", color: "#80ffff", data: [] },
  ],
  customerOptions: [],
  unitOptions: [],
  userNameOptions: [],
  itemNameOptions: [],
  goToAside: undefined,
};
export const callTypes = {
  action: "action",
};

export const SFTSlice = createSlice({
  name: "sft",
  initialState: initialSFTState,
  reducers: {
    startCall: (state, action) => {
      state.actionsLoading = true;
    },
    setSFTDatas: (state, action) => {
      state.actionsLoading = false;
      state.SFTData = action.payload.SFTData;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload.filteredData;
    },
    setClassifiedData: (state, action) => {
      state.classifiedData = action.payload.classifiedData;
    },
    setCustomerOptions: (state, action) => {
      state.customerOptions = action.payload.customerOptions;
    },
    setUnitOptions: (state, action) => {
      state.unitOptions = action.payload.unitOptions;
    },
    setUserNameOptions: (state, action) => {
      state.userNameOptions = action.payload.userNameOptions;
    },
    setItemNameOptions: (state, action) => {
      state.itemNameOptions = action.payload.itemNameOptions;
    },

    setCarouselIndex: (state, action) => {
      state.carouselIndex = action.payload.carouselIndex;
    },

    setGoToAside: (state, action) => {
      state.goToAside = action.payload.goToAside;
    },
  },
});
