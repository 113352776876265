import React from "react";

import { DashBoardListItem } from "./dashBoard/DashBoardListItem"
import { SFTListItem } from "./sftListItem/SFTListItem";
import { ChartListItem } from "./chart/ChartListItem";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";

export function Aside() {
  return (
    <>
      <Divider />
      <List>
        <DashBoardListItem />
      </List>
      <Divider />
      <List>
        <SFTListItem />
      </List>
      <Divider />
      <List>
        <ChartListItem />
      </List>
      <Divider />
    </>
  );
}
