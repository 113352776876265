import React from "react";

import { useHistory } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import DashboardIcon from "@material-ui/icons/Dashboard";

export function DashBoardListItem() {
  const history = useHistory();
  const handleClick = () => {
    history.push("/dashboard");
  };

  return (
    <>
        <ListItem
          button
          disabled
          // selected={window.location.pathname.indexOf("/dashboard") === 0}
          onClick={() => {
            handleClick();
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"儀錶板"} color="primary" />
        </ListItem>
    </>
  );
}
