import axios from "axios";

// 測試環境
const ip = "https://bi-dashboard.aiv.com.tw/api";

// const ip = "https://tglobal-rtsftrack.aiv.com.tw/api";

export const LOGIN_URL = ip + "/session";
export const LOGOUT_URL = ip + "/session";
export const ME_URL = ip + "/member/token";

export const login = (request) => {
  let data = axios.post(LOGIN_URL, request);

  return data;
};

export const logout = () => {
  let data = axios.delete(LOGOUT_URL);

  return data;
};
