import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../app/modules/_redux/sft/SFTActions";

import Box from "@material-ui/core/Box";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Paper from "@material-ui/core/Paper";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EngineeringIcon from "@material-ui/icons/Engineering";
import InsertChartIcon from "@material-ui/icons/InsertChart";

export function Navigation() {
  const [value, setValue] = useState(null);
  const ref = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const { goToAside } = useSelector(
    (state) => ({
      goToAside: state.sft.goToAside,
    }),
    shallowEqual
  );

  const handleChange = (event, newValue) => {
    history.push("/" + newValue);
    setValue(newValue);
    switch (newValue) {
      case "sft":
        // dispatch(actions.setCarouselIndex(0)) //V2.0.0
        if (goToAside) {
          goToAside();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (window.location.pathname.indexOf("/dashboard") === 0) {
      setValue("dashboard");
    } else if (window.location.pathname.indexOf("/sft") === 0) {
      setValue("sft");
    } else if (window.location.pathname.indexOf("/charts") === 0) {
      setValue("charts");
    }
  }, [window.location.pathname]);

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
        style={{ zIndex: 1 }}
      >
        <BottomNavigation value={value} onChange={handleChange}>
          {/* <BottomNavigationAction
            label="儀錶板"
            value="dashboard"
            icon={<DashboardIcon />}
            disabled={true}
          /> */}
          <BottomNavigationAction
            label="即時追蹤工單"
            value="sft"
            icon={<EngineeringIcon />}
          />
          {/* <BottomNavigationAction
            label="圖表"
            value="charts"
            icon={<InsertChartIcon />}
            disabled={true}
          /> */}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
