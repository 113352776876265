import React from "react";

import { useHistory } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import LogoutIcon from '@material-ui/icons/Logout';
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";

export function Logout({ closeMenu }) {
    const history = useHistory();
    const handleClick = () => {
      closeMenu();
      history.push("/logout")
    };

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit"> 登出</Typography>
      </MenuItem>
    </>
  );
}
