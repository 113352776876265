import { LOGIN_URL, LOGOUT_URL, ME_URL } from "../../_redux/auth/AuthCrud";
import userTableMock from "./userTableMock";

export default function mockAuth(mock) {
  mock.onPost(LOGIN_URL).reply(({ data }) => {
    const { account, password } = JSON.parse(data);

    if (account && password) {
      const user = userTableMock.find(
        (x) =>
          x.account.toLowerCase() === account.toLowerCase() &&
          x.password === password
      );

      if (user) {
        return [
          200,
          {
            code: "00000",
            message: "成功",
            data: { token: user.token },
          },
        ];
      }
    }

    return [400];
  });

  mock.onDelete(LOGOUT_URL).reply(({ headers: { Authorization } }) => {
    const token =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);

    if (token) {
      const user = userTableMock.find((x) => x.token === token);

      if (user) {
        return [
          200,
          {
            code: "00000",
            message: "成功",
            data: { token: user.token },
          },
        ];
      }
    }

    return [404];
  });

  mock.onGet(ME_URL).reply(({ headers: { Authorization } }) => {
    const token =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);

    if (token) {
      const user = userTableMock.find((x) => x.token === token);

      if (user) {
        return [
          200,
          {
            code: "00000",
            message: "成功",
            data: { ...user, password: undefined },
          },
        ];
      }
    }

    return [404];
  });

  function generateUserId() {
    const ids = userTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
