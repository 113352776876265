import * as sftAPI from "./SFTCrud.js";
import { SFTSlice } from "./SFTSlice";

const { actions } = SFTSlice;

export const getAllStatusData = () => (dispatch) => {
  dispatch(actions.startCall());
  return sftAPI
    .getAllStatusData()
    .then((res) => {
      const SFTData = res.data;
      dispatch(actions.setSFTDatas({ SFTData: SFTData.reverse() }));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const reloadAllStatusData = () => (dispatch) => {
  return sftAPI
    .getAllStatusData()
    .then((res) => {
      const SFTData = res.data;
      dispatch(actions.setSFTDatas({ SFTData: SFTData.reverse() }));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getFilteredData =
  ({
    rawData,
    displayMode,
    customer,
    quantity,
    unit,
    userName,
    itemName,
    orderId,
    searchMode,
  }) =>
  (dispatch) => {
    let filteredDataTemp = [];

    rawData.map((datum) => {
      filteredDataTemp.push(datum);
    });

    if (displayMode) {
      switch (displayMode) {
        case "online":
          filteredDataTemp = filteredDataTemp.filter(
            (datum) => datum.status === "checkIn" || datum.status === "checkOut"
          );
          break;
        case "intoStockIn":
          filteredDataTemp = filteredDataTemp.filter(
            (datum) => datum.status === "IntoStockIn"
          );
          break;
        case "intoWH":
          filteredDataTemp = filteredDataTemp.filter(
            (datum) => datum.status === "IntoWH"
          );
          break;
        default:
          filteredDataTemp = filteredDataTemp.filter(
            (datum) =>
              datum.status === "checkIn" ||
              datum.status === "checkOut" ||
              datum.status === "IntoStockIn" ||
              datum.status === "IntoWH" ||
              datum.status === "release"
          );
          break;
      }
    }

    if (searchMode) {
      switch (searchMode) {
        case "finished":
          filteredDataTemp = filteredDataTemp.filter(
            (datum) => datum.proccesseName === "OQC" || datum.finished
          );
          break;
        default:
          filteredDataTemp = filteredDataTemp.filter(
            (datum) => !(datum.proccesseName === "OQC" || datum.finished)
          );
          break;
      }
    }

    if (customer && customer.length > 0) {
      filteredDataTemp = filteredDataTemp.filter((datum) =>
        customer.includes(datum.customer)
      );
    }

    if (quantity) {
      filteredDataTemp = filteredDataTemp.filter(
        (datum) => datum.quantity === quantity
      );
    }

    if (unit && unit.length > 0) {
      filteredDataTemp = filteredDataTemp.filter((datum) =>
        unit.includes(datum.unit)
      );
    }

    if (userName && userName.length > 0) {
      filteredDataTemp = filteredDataTemp.filter((datum) =>
        userName.includes(datum.userName)
      );
    }

    if (itemName && itemName.length > 0) {
      filteredDataTemp = filteredDataTemp.filter((datum) =>
        itemName.includes(datum.itemName)
      );
    }

    if (orderId) {
      filteredDataTemp = filteredDataTemp.filter(
        (datum) => datum.orderId.indexOf(orderId) > -1
      );
    }

    dispatch(actions.setFilteredData({ filteredData: filteredDataTemp }));
  };

export const getClassifiedData =
  ({ filteredData, displayMode }) =>
  (dispatch) => {
    let finishData = [];
    let otherData = [];
    let adhesiveData = [];
    let cuttingData = [];
    let qcData = [];
    let trimmingData = [];

    function classification(datum) {
      switch (datum.proccesseName) {
        case "背膠":
          adhesiveData.push(datum);
          break;
        case "沖型":
          cuttingData.push(datum);
          break;
        case "裁切":
          cuttingData.push(datum);
          break;
        case "FQC":
          qcData.push(datum);
          break;
        case "OQC":
          finishData.push(datum);
          break;
        case "修邊":
          trimmingData.push(datum);
          break;
        default:
          otherData.push(datum);
          break;
      }
    }

    function getDisplayMakeOrderList(displayMode) {
      switch (displayMode) {
        case "default":
          return [
            { proccesseName: "其他", color: "#c280ff", data: otherData },
            { proccesseName: "背膠", color: "#eff", data: adhesiveData },
            { proccesseName: "裁切/沖型", color: "#f0e199", data: cuttingData },
            { proccesseName: "QC", color: "#efb5b9", data: qcData },
            { proccesseName: "修邊", color: "#9cffd0", data: trimmingData },
            { proccesseName: "入庫", color: "#80ffff", data: finishData },
          ];
        case "online":
          return [
            { proccesseName: "其他", color: "#c280ff", data: otherData },
            { proccesseName: "背膠", color: "#eff", data: adhesiveData },
            { proccesseName: "裁切/沖型", color: "#f0e199", data: cuttingData },
            { proccesseName: "QC", color: "#efb5b9", data: qcData },
            { proccesseName: "修邊", color: "#9cffd0", data: trimmingData },
          ];
        case "intoStockIn":
          return [
            { proccesseName: "其他", color: "#c280ff", data: otherData },
            { proccesseName: "背膠", color: "#eff", data: adhesiveData },
            { proccesseName: "裁切/沖型", color: "#f0e199", data: cuttingData },
            { proccesseName: "QC", color: "#efb5b9", data: qcData },
            { proccesseName: "修邊", color: "#9cffd0", data: trimmingData },
          ];
        case "intoWH":
          return [
            { proccesseName: "其他", color: "#c280ff", data: otherData },
            { proccesseName: "背膠", color: "#eff", data: adhesiveData },
            { proccesseName: "裁切/沖型", color: "#f0e199", data: cuttingData },
            { proccesseName: "QC", color: "#efb5b9", data: qcData },
            { proccesseName: "修邊", color: "#9cffd0", data: trimmingData },
          ];
        default:
          return [
            { proccesseName: "其他", color: "#c280ff", data: otherData },
            { proccesseName: "背膠", color: "#eff", data: adhesiveData },
            { proccesseName: "裁切/沖型", color: "#f0e199", data: cuttingData },
            { proccesseName: "QC", color: "#efb5b9", data: qcData },
            { proccesseName: "修邊", color: "#9cffd0", data: trimmingData },
            { proccesseName: "入庫", color: "#80ffff", data: finishData },
          ];
      }
    }

    filteredData.map((datum) => {
      switch (displayMode) {
        case "default":
          if (datum.finished) {
            finishData.push(datum);
          } else {
            classification(datum);
          }
          break;
        case "online":
          if (datum.status === "checkIn" || datum.status === "checkOut") {
            if (datum.finished) {
              finishData.push(datum);
            } else {
              classification(datum);
            }
          }
          break;
        case "intoStockIn":
          if (datum.status === "IntoStockIn") {
            if (datum.finished) {
              finishData.push(datum);
            } else {
              classification(datum);
            }
          }
          break;
        case "intoWH":
          if (datum.status === "IntoWH") {
            if (datum.finished) {
              finishData.push(datum);
            } else {
              classification(datum);
            }
          }
          break;
        default:
          classification(datum);
          break;
      }
    });

    let classifiedDataTemp = getDisplayMakeOrderList(displayMode);
    dispatch(actions.setClassifiedData({ classifiedData: classifiedDataTemp }));
  };

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const getCustomerOptions =
  ({ rawData }) =>
  (dispatch) => {
    let customerOptionsTemp = [];

    rawData.map((datum) => {
      if (datum.customer) {
        customerOptionsTemp.push(datum.customer);
      }
    });

    customerOptionsTemp = customerOptionsTemp.filter(onlyUnique).sort();

    dispatch(
      actions.setCustomerOptions({ customerOptions: customerOptionsTemp })
    );
  };

export const getUnitOptions =
  ({ rawData }) =>
  (dispatch) => {
    let unitOptionsTemp = [];

    rawData.map((datum) => {
      if (datum.unit) {
        unitOptionsTemp.push(datum.unit);
      }
    });

    unitOptionsTemp = unitOptionsTemp.filter(onlyUnique).sort();

    dispatch(actions.setUnitOptions({ unitOptions: unitOptionsTemp }));
  };

export const getUserNameOptions =
  ({ rawData }) =>
  (dispatch) => {
    let userNameOptionsTemp = [];

    rawData.map((datum) => {
      if (datum.userName) {
        userNameOptionsTemp.push(datum.userName);
      }
    });

    userNameOptionsTemp = userNameOptionsTemp.filter(onlyUnique).sort();

    dispatch(
      actions.setUserNameOptions({ userNameOptions: userNameOptionsTemp })
    );
  };

export const getItemNameOptions =
  ({ rawData }) =>
  (dispatch) => {
    let itemNameOptionsTemp = [];

    rawData.map((datum) => {
      if (datum.itemName) {
        itemNameOptionsTemp.push(datum.itemName);
      }
    });

    itemNameOptionsTemp = itemNameOptionsTemp.filter(onlyUnique).sort();

    dispatch(
      actions.setItemNameOptions({ itemNameOptions: itemNameOptionsTemp })
    );
  };

export const setCarouselIndex = (value) => (dispatch) => {
  dispatch(actions.setCarouselIndex({ carouselIndex: value }));
};

export const setGoToAside = (value) => (dispatch) => {
  dispatch(actions.setGoToAside({ goToAside: value }));
};
