import React, { useState } from "react";

import { Navigation } from "./navigation/Navigation";
import { ScrollTop } from "./scrollTop/ScrollTop";
import { ThreeDots } from "./threeDots/ThreeDots";

import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export function MobileLayout({ children }) {
  return (
    <Box>
      <AppBar>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            T-Global Technology
          </Typography>
          <ThreeDots />
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Container maxWidth="false">
        <Box sx={{ m:2 }}>{children}</Box>
      </Container>
      <ScrollTop bottom={"calc(3% + 56px)"} right={"5%"} />
      <Navigation />
    </Box>
  );
}
