import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { BaseRoutes } from "./BaseRoutes";
import { ErrorsPage } from "./pages/error/ErrorsPage";

export function Routes() {
  return (
    <>
      <Switch>
        <Route path="/error" component={ErrorsPage} />
        <Route path="/" component={BaseRoutes} />
      </Switch>
    </>
  );
}
