import * as authAPI from "./AuthCrud";
import { actions } from "./AuthRedux";

export const login =
  (request, setActionLoading, setErrorMessage) => (dispatch) => {
    setActionLoading(true);
    return authAPI
      .login(request)
      .then((response) => {
        setActionLoading(false);
        const res = response.data;
        switch (res.code) {
          case "00000":
            dispatch(actions.login({ session: res.data }));
            break;
          default:
            setErrorMessage(res.message);
        }
      })
      .catch(() => {
        setActionLoading(false);
        setErrorMessage("登入失敗");
      });
  };

export const logout = () => (dispatch) => {
  return authAPI.logout().then((request) => {
    dispatch(actions.logout()).catch((error) => {
      console.log(error);
    });
  });
};
