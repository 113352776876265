import { toAbsoluteUrl } from "../../../_helpers/AssetsHelpers";

export default [
  {
    id: 1,
    account: "aivalue",
    password: "aiv82857354",
    roles: 1, // Administrator
    fullname: "智慧價值",
    token: "aivAdmin",
  },
  {
    id: 2,
    account: "tglobal",
    password: "password",
    roles: 1, // Administrator
    fullname: "高柏",
    token: "tGlobalAdmin",
  },
  {
    id: 3,
    account: "demo",
    password: "demo",
    roles: 2, // Member
    fullname: "業務",
    token: "tGlobalMember",
  },
];
