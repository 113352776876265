import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import { SplashScreen } from "../../../../layout/_core/SplashScreen";
import { MobileSplashScreen } from "../../../../layout/_core/MobileSplashScreen";

import { useWindowDimensions } from "../../../../layout/_core/useWindowDimensions";

const LoginPage = lazy(() => import("./login/LoginPage"));
const LogoutPage = lazy(() => import("./logout/LogoutPage"));

export function AuthPage() {
  const { hieght, width } = useWindowDimensions();

  return (
    <>
      <Suspense
        fallback={width > 576 ? <SplashScreen /> : <MobileSplashScreen />}
      >
        <Switch>
          <Redirect exact from="/" to="/auth/login" />
          <Route path="/auth/login" component={LoginPage} />
          <Redirect to="/auth/login" />
        </Switch>
      </Suspense>
    </>
  );
}
