import React, { useState } from "react";

import { Report } from "./report/Report";
import { Info } from "./info/Info";
import { Logout } from "./logout/Logout";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export function ThreeDots() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [MenuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (e) => {
    setMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="more"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={MenuAnchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <Report closeMenu={handleMenuClose} />
        {/* <Info closeMenu={handleMenuClose} /> */}
        <Logout closeMenu={handleMenuClose} />
      </Menu>
    </>
  );
}
