import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import HelpIcon from "@material-ui/icons/Help";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";

export function Report({ closeMenu }) {
  const handleClick = () => {
    closeMenu();
    window.open("https://forms.gle/CxJF9oobN78kZRrP9", "_blank");
  };

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <HelpIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit"> 回報問題</Typography>
      </MenuItem>
    </>
  );
}
