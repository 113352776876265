import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import { SplashScreen } from "../layout/_core/SplashScreen";
import { MobileSplashScreen } from "../layout/_core/MobileSplashScreen";

import { useWindowDimensions } from "../layout/_core/useWindowDimensions";

import { LogoutPage } from "./modules/pages/auth/logout/LogoutPage";

const DashboardPage = lazy(() =>
  import("./modules/pages/dashboard/DashboardPage")
);
const ChartsPage = lazy(() => import("./modules/pages/charts/ChartsPage"));
const InfoPage = lazy(() => import("./modules/pages/info/InfoPage"));
const SFTPage = lazy(() => import("./modules/pages/sft/SFTPage"));

export function BasePage() {
  const { hieght, width } = useWindowDimensions();

  return (
    <>
      <Suspense
        fallback={width > 576 ? <SplashScreen /> : <MobileSplashScreen />}
      >
        <Switch>
          <Redirect exact from="/" to="/sft" />
          <Redirect from="/auth" to="/sft" />
          <Route path="/dashboard" component={DashboardPage} />
          <Route path="/sft" component={SFTPage} />
          <Route path="/charts" component={ChartsPage} />
          <Route path="/info" component={InfoPage} />
          <Route path="/logout" component={LogoutPage} />
          <Redirect to="/error/error-v1" />
        </Switch>
      </Suspense>
    </>
  );
}
