import React from "react";

import { toAbsoluteUrl } from "../../app/_helpers/AssetsHelpers";

import Backdrop from "@material-ui/core/Backdrop";

export function SplashScreen() {
  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor:"#303030",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <img src={toAbsoluteUrl("./logo320.png")}></img>
      </Backdrop>
    </>
  );
}
