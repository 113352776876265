import React from "react";

import { useHistory } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EngineeringIcon from "@material-ui/icons/Engineering";

export function SFTListItem() {
  const history = useHistory();
  const handleClick = () => {
    history.push("/sft");
  };

  return (
    <>
      <ListItem
        button
        // selected={window.location.pathname.indexOf("/sft") === 0}
        onClick={() => {
          handleClick();
        }}
      >
        <ListItemIcon>
          <EngineeringIcon />
        </ListItemIcon>
        <ListItemText primary={"即時追蹤生產系統"} />
      </ListItem>
    </>
  );
}
