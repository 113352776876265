import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";

import InsertChartIcon from "@material-ui/icons/InsertChart";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

export function ChartListItem() {
  const [open, setOpen] = useState(false);
  const [count,setCount] = useState(0)
  const history = useHistory();
  const handleClick = () => {
    setOpen(!open);
  };


//   var sum = 0;
// function clickCount() {
//   count += 1;
//   console.log("clickCount",count)
// };


  // const clickCount=()=>{
  //   console.log("123")
  //   let clickCount=0
  //   clickCount=clickCount+1
  //   setCount(clickCount)
  // }


  return (
    <>
      <ListItem
        button
        
        onClick={() => {
          handleClick();
         
        }}
      >
        <ListItemIcon>
          <InsertChartIcon />
        </ListItemIcon>
        <ListItemText primary={"圖表"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <ListItem
            button
            // selected={window.location.pathname.indexOf("/charts/chart1") === 0}
            sx={{ pl: 4 }}
            onClick={() => {
              // clickCount()
              history.push("/charts/chart1");
            }}
          >
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary={"助撕/拉拔/斷法"} />
          </ListItem>
          <ListItem
            button
            
            // selected={window.location.pathname.indexOf("/charts/chart2") === 0}
            sx={{ pl: 4 }}
            onClick={() => {
              // clickCount()
              history.push("/charts/chart2");
              
            }}
          >
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary={"跳間隔/裁條狀"} />
          </ListItem>
          <ListItem
            button
           
            // selected={window.location.pathname.indexOf("/charts/chart3") === 0}
            sx={{ pl: 4 }}
            onClick={() => {
              history.push("/charts/chart3");
              // clickCount()
            }}
          >
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary={"品名 - 機時/人時"} />
          </ListItem>
          <ListItem
            button
           
            // selected={window.location.pathname.indexOf("/charts/chart3") === 0}
            sx={{ pl: 4 }}
            onClick={() => {
              history.push("/charts/chart4");
              // clickCount()
            }}
          >
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary={"料號 - 機時/人時"} />
          </ListItem>
          <ListItem
            button
           
            // selected={window.location.pathname.indexOf("/charts/chart3") === 0}
            sx={{ pl: 4 }}
            onClick={() => {
              history.push("/charts/chart5");
              // clickCount()
            }}
          >
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary={"機台使用效率"} />
          </ListItem>
          {/* <button id="clickme" onClick={()=>{clickCount()}}>Click me: 0</button> */}

        </List>
      </Collapse>
    </>
  );
}
