import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as actions from "../../../_redux/auth/AuthAction"

export function LogoutPage() {
  const dispatch = useDispatch();

  const { authToken } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.logout());
  }, [authToken]);

  return <></>;
}
