import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
  actionsLoading: false,
  manufacturingMethodData: [],
  productnameTimeData: [],
  processTimeData: [],
  productnameTimeChartData:[],
  //SelectValue
  selectValue: [],
  //manufacturingMethodData
  processNameFilteredData: [],
  itemIdFilteredData: [],
  itemNameFilteredData: [],
  processNameOptions: [],
  itemIdOptions: [],
  itemNameOptions: [],
  //productnameTimeData
  pntProcessNameFilteredData: [],
  pntItemIdFilteredData: [],
  pntPunchTypeFilteredData: [],
  pntProcessNameOptions: [],
  pntItemIdOptions: [],
  pntPunchTypeOptions: [],
  //processTimeData
  ptProcessNameFilteredData: [],
  ptItemIdFilteredData: [],
  ptPunchTypeFilteredData: [],
  ptProcessNameOptions: [],
  ptItemIdOptions: [],
  ptPunchTypeOptions: [],
  //FinalFilteredData
  mmdFilteredData: [],
  pntFilteredData: [],
  pntFilteredData2:[],
  pntFilteredData3:[],
  ptFilteredData: [],
  //FinalFilteredSeries
  //1
  d1c1Series: { name: [], data: [] },
  d1c2Series: { name: [], data: [] },
  d1c3Series: { name: [], data: [] },
  d1c4Series: { name: [], data: [] },
  //2
  d2c1Series: { name: [], data: [] },
  d2c2Series: { name: [], data: [] },
  d2c3Series: { name: [], data: [] },
  d2c4Series: { name: [], data: [] },
  //3
  d3c1Series: { name: [], data: [] },
  d3c2Series: { name: [], data: [] },
  //4
  d4c1Series: { name: [], data: [] },
  d4c2Series: { name: [], data: [] },
  //5
  d5c1Series: { name: [], data: [] },
  d5c2Series: { name: [], data: [] },
  d5c3Series: { name: [], data: [] },
};
export const callTypes = {
  action: "action",
};

export const DashboardSlice = createSlice({
  name: "dashborad",
  initialState: initialDashboardState,
  reducers: {
    startCall: (state, action) => {
      state.actionsLoading = true;
    },
    setManufacturingMethodData: (state, action) => {
      state.manufacturingMethodData = action.payload.manufacturingMethodData;
      state.actionsLoading = false;
    },
    setProductnameTimeData: (state, action) => {
      state.productnameTimeData = action.payload.productnameTimeData;
      state.actionsLoading = false;
    },
    setProcessTimeData: (state, action) => {
      state.processTimeData = action.payload.processTimeData;
      state.actionsLoading = false;
    },
    setProductnameTimeChartData: (state, action) => {
      state.productnameTimeChartData = action.payload.productnameTimeChartData;
      state.actionsLoading = false;
    },
    //SelectValue
    setSelectValue: (state, action) => {
      state.selectValue = action.payload.selectValue;
    },
    //ManufacturingMethodData
    setProcessNameFilteredData: (state, action) => {
      state.processNameFilteredData = action.payload.processNameFilteredData;
    },
    setItemIdFilteredData: (state, action) => {
      state.itemIdFilteredData = action.payload.itemIdFilteredData;
    },
    setItemNameFilteredData: (state, action) => {
      state.itemNameFilteredData = action.payload.itemNameFilteredData;
    },
    setProcessNameOptions: (state, action) => {
      state.processNameOptions = action.payload.processNameOptions;
    },
    setItemIdOptions: (state, action) => {
      state.itemIdOptions = action.payload.itemIdOptions;
    },
    setItemNameOptions: (state, action) => {
      state.itemNameOptions = action.payload.itemNameOptions;
    },
    //ProductnameTimeData
    setPntProcessNameFilteredData: (state, action) => {
      state.pntProcessNameFilteredData =
        action.payload.pntProcessNameFilteredData;
    },
    setPntItemIdFilteredData: (state, action) => {
      state.pntItemIdFilteredData = action.payload.pntItemIdFilteredData;
    },
    setPntPunchTypeFilteredData: (state, action) => {
      state.pntPunchTypeFilteredData = action.payload.pntPunchTypeFilteredData;
    },
    setPntProcessNameOptions: (state, action) => {
      state.pntProcessNameOptions = action.payload.pntProcessNameOptions;
    },
    setPntItemIdOptions: (state, action) => {
      state.pntItemIdOptions = action.payload.pntItemIdOptions;
    },
    setPntPunchTypeOptions: (state, action) => {
      state.pntPunchTypeOptions = action.payload.pntPunchTypeOptions;
    },
    //ProcessTimeData
    setPtProcessNameFilteredData: (state, action) => {
      state.ptProcessNameFilteredData =
        action.payload.ptProcessNameFilteredData;
    },
    setPtItemIdFilteredData: (state, action) => {
      state.ptItemIdFilteredData = action.payload.ptItemIdFilteredData;
    },
    setPtPunchTypeFilteredData: (state, action) => {
      state.ptPunchTypeFilteredData = action.payload.ptPunchTypeFilteredData;
    },
    setPtProcessNameOptions: (state, action) => {
      state.ptProcessNameOptions = action.payload.ptProcessNameOptions;
    },
    setPtItemIdOptions: (state, action) => {
      state.ptItemIdOptions = action.payload.ptItemIdOptions;
    },
    setPtPunchTypeOptions: (state, action) => {
      state.ptPunchTypeOptions = action.payload.ptPunchTypeOptions;
    },
    //FinalFilteredData
    setMmdFilteredData: (state, action) => {
      state.mmdFilteredData = action.payload.mmdFilteredData;
    },
    setPntFilteredData: (state, action) => {
      state.pntFilteredData = action.payload.pntFilteredData;
    },
    setPntFilteredData2: (state, action) => {
      state.pntFilteredData2 = action.payload.pntFilteredData2;
    },
    setPntFilteredData3: (state, action) => {
      state.pntFilteredData3 = action.payload.pntFilteredData3;
    },
    setPtFilteredData: (state, action) => {
      state.ptFilteredData = action.payload.ptFilteredData;
    },
    //FinalFilteredSeries
    //d1
    setD1C1Series: (state, action) => {
      state.d1c1Series = action.payload.d1c1Series;
    },
    setD1C2Series: (state, action) => {
      state.d1c2Series = action.payload.d1c2Series;
    },
    setD1C3Series: (state, action) => {
      state.d1c3Series = action.payload.d1c3Series;
    },
    setD1C4Series: (state, action) => {
      state.d1c4Series = action.payload.d1c4Series;
    },
    //d2
    setD2C1Series: (state, action) => {
      state.d2c1Series = action.payload.d2c1Series;
    },
    setD2C2Series: (state, action) => {
      state.d2c2Series = action.payload.d2c2Series;
    },
    setD2C3Series: (state, action) => {
      state.d2c3Series = action.payload.d2c3Series;
    },
    setD2C4Series: (state, action) => {
      state.d2c4Series = action.payload.d2c4Series;
    },
    //d3
    setD3C1Series: (state, action) => {
      state.d3c1Series = action.payload.d3c1Series;
    },
    setD3C2Series: (state, action) => {
      state.d3c2Series = action.payload.d3c2Series;
    },
    //d4
    setD4C1Series: (state, action) => {
      state.d4c1Series = action.payload.d4c1Series;
    },
    setD4C2Series: (state, action) => {
      state.d4c2Series = action.payload.d4c2Series;
    },
    //d5
    setD5C1Series: (state, action) => {
      state.d5c1Series = action.payload.d5c1Series;
    },
    setD5C2Series: (state, action) => {
      state.d5c2Series = action.payload.d5c2Series;
    },
    setD5C3Series: (state, action) => {
      state.d5c3Series = action.payload.d5c3Series;
    },
  },
});
