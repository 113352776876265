import React from "react";
import { toAbsoluteUrl } from "../../_helpers/AssetsHelpers";

import Grid from "@material-ui/core/Grid";

export function ErrorPage1() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
      style={{
        backgroundImage: `url(${toAbsoluteUrl("/error/bg1.jpg")})`,
        height: "100vh",
        width: "100vw",
      }}
    >
      <Grid item>
        <span style={{ color: "white", fontSize: "5.5rem", fontWeight: 700 }}>
          OOPS!
        </span>
      </Grid>
      <Grid item style={{ marginBottom: "5%" }}>
        <span style={{ color: "white", fontSize: "2.5rem", fontWeight: 700 }}>
          Something went wrong here
        </span>
      </Grid>
    </Grid>
  );
}
