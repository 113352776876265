import React from "react";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import {
  SplashScreen,
  MobileSplashScreen,
  MaterialThemeProvider,
} from "../layout/index";
import { useWindowDimensions } from "../layout/_core/useWindowDimensions";

function App({ store, persistor, basename }) {
  const { height, width } = useWindowDimensions();
  return (
    <Provider store={store}>
      <PersistGate
        persistor={persistor}
        loading={width > 576 ? <SplashScreen /> : <MobileSplashScreen />}
      >
        <React.Suspense
          fallback={width > 576 ? <SplashScreen /> : <MobileSplashScreen />}
        >
          <BrowserRouter basename={basename}>
            <MaterialThemeProvider>
              <Routes />
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
