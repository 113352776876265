import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import store,{ persistor } from "./redux/store";
import * as _redux from "./redux";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

_redux.mockAxios(axios);
_redux.setUpAxios(axios, store);

ReactDOM.render(
  <React.StrictMode>
    <App store={store} persistor={persistor}/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
