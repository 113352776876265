import axios from "axios";

const ip = process.env.REACT_APP_ENDPOINT;

export const GET_ALL_STATUS_DATA = ip + "/sft/temp";

export const getAllStatusData = () => {
  let data = axios.get(GET_ALL_STATUS_DATA);

  return data;
};
