import React from "react";

import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";

import { Layout } from "../layout/index";
import { MobileLayout } from "../layout/index";
import { BasePage } from "./BasePage";
import { AuthPage } from "./modules/pages/auth/AuthPage";
import { useWindowDimensions } from "../layout/_core/useWindowDimensions";


import CssBaseline from "@material-ui/core/CssBaseline";

export function BaseRoutes() {
  const { height, width } = useWindowDimensions();

  const { authToken } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {authToken ? (
        width > 576 ? (
          <Layout>
            <CssBaseline />
            <BasePage />
          </Layout>
        ) : (
          <MobileLayout>
            <CssBaseline />
            <BasePage />
          </MobileLayout>
        )
      ) : (
        <Route>
          <CssBaseline />
          <AuthPage />
        </Route>
      )}
    </Switch>
  );
}
