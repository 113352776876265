import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/_redux/auth/AuthRedux";
import { SFTSlice } from "../app/modules/_redux/sft/SFTSlice";
import { DashboardSlice } from "../app/modules/_redux/dashboard/DashboardSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  sft: SFTSlice.reducer,
  dashboard:DashboardSlice.reducer
});

export function* rootSaga() {}
